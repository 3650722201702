import {
    ApolloClient,
    ApolloProvider,
    InMemoryCache,
    createHttpLink,
    from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { ReactNode } from 'react';

const cache = new InMemoryCache();

const httpLink = createHttpLink({
    uri: `${CONFIG.apiUrl}/graphql`,
    credentials: 'include',
});

const retryLink = new RetryLink({
    delay: {
        initial: 300,
        max: 5000,
        jitter: true,
    },
    attempts: {
        max: 3,
    },
});

// Ref: https://github.com/apollographql/apollo-link/tree/master/packages/apollo-link-error
// Handle session errors
const tokenErrorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
            console.warn(
                `[GraphQL error]: ${message}; Path: ${path} Location:`,
                locations,
            );
        });
    }

    if (networkError) {
        console.warn(`[Network error]: ${networkError}`);
    }

    return undefined;
});

const client = new ApolloClient({
    // The order of the links matter. The retry link should come first to retry dropped network calls.
    link: from([retryLink, tokenErrorLink, httpLink]),
    cache,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'network-only',
        },
        query: {
            fetchPolicy: 'network-only',
            errorPolicy: 'all', // Both data and error.graphQLErrors are populated, enabling you to render both partial results and error information.
        },
        mutate: {
            errorPolicy: 'all', // Both data and error.graphQLErrors are populated, enabling you to render both partial results and error information.
        },
    },
});

const ApolloClientComponent = (props: { children: ReactNode }) => (
    <ApolloProvider client={client}>{props.children}</ApolloProvider>
);

export default ApolloClientComponent;
