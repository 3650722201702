import { graphql } from '@gql/gql';

export const AUTOPILOT_QUERY = graphql(`
    query AutopilotQuery {
        userBundleProducts {
            id
            quantity
            frequency
            nextDeliveryDate
            nextOrderIntentId
            product {
                id
                name
                price
                retail_price
                subscription_price
                refill_settings {
                    frequency {
                        value
                        origin
                    }
                }
                service_type
                sellable_qty
                sellable_count
                sellable_unit
                packaging_sustainability_info
                images {
                    sm
                }
                primary_category {
                    display_name
                    slug
                }
                top_level_category {
                    display_name
                    slug
                }
            }
        }
    }
`);

export const NEXT_DELIVERY_DATES_QUERY = graphql(`
    query NextDeliveryDates(
        $nowDate: DateString!
        $numNextDeliveries: Int = 6
    ) {
        orderIntents(
            limit: $numNextDeliveries
            afterOrOnDate: $nowDate
            excludeLocked: true
        ) {
            id
            expectedDeliveryDate
        }
    }
`);
