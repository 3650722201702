/* eslint-disable @typescript-eslint/naming-convention */

/* eslint-disable camelcase */
import { ErrorService } from '@services/error';

import { parseQueryString } from './url';

/** ***************************Friendbuy track Calls******************************* */

export const FriendbuyTrackPage = (pageName) => {
    if (CONFIG.friendbuy.enabled) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        window &&
            window.friendbuyAPI &&
            window.friendbuyAPI.push(['track', 'page', { name: pageName }]);
        return 'Friendbuy: successfully tracking page';
    }
    return null;
};

export const FriendbuyTrackUser = (user) => {
    if (CONFIG.friendbuy.enabled) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        window &&
            window.friendbuyAPI &&
            window.friendbuyAPI.push([
                'track',
                'customer',
                {
                    ...user,
                },
            ]);
        return 'Friendbuy: successfully tracking user';
    }
    return null;
};

export const FriendbuyGetVisitorStatus = (callback) => {
    let status = null;
    if (CONFIG.friendbuy.enabled) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        window &&
            window.friendbuyAPI &&
            window.friendbuyAPI.push(['getVisitorStatus', callback]);
    }

    status = window.friendbuyVisitorStatus;

    return status;
};

export const FriendbuyTrackSignup = (email, id, name) => {
    if (CONFIG.friendbuy.enabled) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        window &&
            window.friendbuyAPI &&
            window.friendbuyAPI.push([
                'track',
                'sign_up',
                {
                    email,
                    id,
                    name,
                },
            ]);
    }
};

/** ***************************Segment track Calls******************************* */

export const SegmentIdentify = (userId, userTraits) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window &&
        window.analytics &&
        window.analytics.identify(userId, {
            city_id: userTraits?.location?.city_id,
            ...userTraits,
        });
};

// Segment reset method  is used only clears the cookies and localStorage created by Segment and is useful when logging out a user
export const SegmentReset = async () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window && window.analytics && window.analytics.reset();
};

export const SegmentPageView = (url, properties, options) => {
    // Page events have following parameters: analytics.page([category], [name], [properties], [options], [callback]);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window &&
        window.analytics &&
        window.analytics.page(url, properties, options);
};

/**
 * Send segment coupon used data
 * @param {number} userId user id
 * @param {string} couponSubmitted coupon submitted to be used
 */
export const SegmentCouponUsed = (userId, couponSubmitted) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window &&
        window.analytics &&
        window.analytics.track('Coupon Used', {
            userId,
            couponSubmitted,
        });
};

/**
 * User has clicked autopilot add more button
 */
export const SegmentAutopilotAddMoreClicked = (
    autopilot_container_Id,
    has_new_products,
) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window &&
        window.analytics &&
        window.analytics.track('Autopilot Add More Clicked', {
            autopilot_container_Id,
            has_new_products,
        });
};

export const SegmentProductsSorted = (sortbyMethod) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window &&
        window.analytics &&
        window.analytics.track('User Sorted Products', {
            sortbyMethod,
        });
};

/**
 * User has clicked Inventory curated list tab
 */
export const SegmentInventoryCuratedListClicked = (tabName) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window &&
        window.analytics &&
        window.analytics.track('Inventory Curated List Clicked', {
            tab_name: tabName,
        });
};

/** *************************** Google Analytics Calls ******************************* */
// The following is recommened by Google before every ecommerce spec tracking call: `dataLayer.push({ ecommerce: null });`

export const GTMPageView = (url) => {
    const pageEvent = {
        event: 'pageview',
        page: url,
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window && window.dataLayer && window.dataLayer.push(pageEvent);
    return pageEvent;
};

/** ***************************Listrak Segment Calls******************************* */

export const CouponEntered = (coupon_name, location = 'dashboard') => {
    window?.analytics?.track('Coupon Entered', {
        coupon_name,
        location,
    });
};

export const CouponApplied = (
    coupon_id,
    coupon_name,
    discountType,
    discount,
    location = 'dashboard',
) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window &&
        window.analytics &&
        window.analytics.track('Coupon Applied', {
            coupon_id,
            coupon_name,
            discountType,
            discount,
            location,
        });
};

export const CouponDenied = (
    coupon_name,
    location = 'dashboard',
    reason = '',
) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window &&
        window.analytics &&
        window.analytics.track('Coupon Denied', {
            coupon_name,
            location,
            reason,
        });
};

/**
 * Send segment data when a referral component is clicked
 * @param {string} component the type of component clicked (tile or banner)
 */
export const ReferralComponentClicked = (component) => {
    window?.analytics?.track('Referral Component Clicked', {
        component,
    });
};

export const SidebarClicks = (sidebar_link) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window &&
        window.analytics &&
        window.analytics.track('Sidebar Clicked', { sidebar_link });
};

export const OpenedAccordion = (name) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window &&
        window.analytics &&
        window.analytics.track('Opened Accordion', {
            name,
        });
};

export function analyticsModalViewed(type) {
    window.analytics?.track('Modal Viewed', { type });
}

export function analyticsModalDismissed(type) {
    window.analytics?.track('Modal Dismissed', { type });
}

/**
 * Accepts a URL and returns an object composed of any UTM query parameters
 * If there are multiple parameters with the same key, only the first one will be returned
 */
export const parseUtm = (url) => {
    const query = parseQueryString(url);

    const {
        utm_source,
        utm_medium,
        utm_campaign,
        utm_term,
        utm_content,
        utm_id,
    } = query;

    const campaign = {};

    if (utm_id) {
        campaign.id = utm_id;
    }

    if (utm_source) {
        if (typeof utm_source === 'string') {
            campaign.source = utm_source;
        } else if (Array.isArray(utm_source)) {
            [campaign.source] = utm_source;
            ErrorService.captureError(
                new Error(
                    `Multiple utm_source parameters found: ${utm_source.join(
                        ', ',
                    )}`,
                ),
            );
        }
    }

    if (utm_medium) {
        if (typeof utm_medium === 'string') {
            campaign.medium = utm_medium;
        } else if (Array.isArray(utm_medium)) {
            [campaign.medium] = utm_medium;
            ErrorService.captureError(
                new Error(
                    `Multiple utm_source parameters found: ${utm_medium.join(
                        ', ',
                    )}`,
                ),
            );
        }
    }

    if (utm_campaign) {
        if (typeof utm_campaign === 'string') {
            campaign.name = utm_campaign;
        } else if (Array.isArray(utm_campaign)) {
            [campaign.name] = utm_campaign;
            ErrorService.captureError(
                new Error(
                    `Multiple utm_source parameters found: ${utm_campaign.join(
                        ', ',
                    )}`,
                ),
            );
        }
    }

    if (utm_term) {
        campaign.term = utm_term;
    }

    if (utm_content) {
        campaign.content = utm_content;
    }

    if (Object.keys(campaign).length) {
        return {
            context: {
                campaign,
            },
        };
    }
    return undefined;
};
